import {ChangeDetectionStrategy, Component, Inject, Input, PLATFORM_ID} from '@angular/core'
import {LinkService} from '../../../../core/i18n/link.service'
import {PUBLIC_ROUTES} from '../../../routing/public.routes'
import {ServicesSection} from '../../../../core/content/types/services-section.types'
import {CallToActionButtonStyle} from '../../../../craft/button/call-to-action/call-to-action.component'
import {ButtonModule} from '../../../../craft/button/button.module'
import {TranslocoDirective} from '@ngneat/transloco'
import {CardsModule} from '../../../../craft/cards/cards.module'
import {ContentSectionComponent} from '../../../../craft/content-section/content-section.component'

type CallToActionMapping = {
  [key in ItemsCallToActionColor]: CallToActionButtonStyle
}

@Component({
  selector: 'bgo-services-section',
  templateUrl: './services-section.component.html',
  styles: [':host { @apply block; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {ngSkipHydration: 'true'}, //eslint-disable-line @angular-eslint/no-host-metadata-property
  imports: [ContentSectionComponent, ButtonModule, TranslocoDirective, CardsModule],
  standalone: true,
})
export class ServicesSectionComponent {
  @Input() servicesSection!: ServicesSection
  @Input() showAllServicesButton = true
  @Input() itemsSeparator = false
  @Input() itemsElevation: ItemsElevation = 'flat'
  @Input() itemsCallToActionAppearance: ItemsCallToActionAppearance = 'hidden'
  @Input() itemsCallToActionColor: ItemsCallToActionColor = 'president'

  readonly callToActionMapping: CallToActionMapping = {
    grey: 'secondary',
    president: 'primary',
    vermilion: 'hero',
  }
  constructor(
    readonly linkService: LinkService,
    @Inject(PLATFORM_ID) private readonly platformId: Record<string, unknown>,
  ) {}

  navigateToAllServices(): void {
    this.linkService.navigateTo(PUBLIC_ROUTES.SERVICE_OVERVIEW_PAGE, {routeParams: {pageSlug: 'overview'}})
  }
}

export type ItemsElevation = 'flat' | 'elevated'
export type ItemsCallToActionAppearance = 'hidden' | 'button' | 'link'
export type ItemsCallToActionColor = 'president' | 'grey' | 'vermilion'
