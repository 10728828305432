<ng-container *transloco="let t">
  <cft-content-section
    [sectionId]="servicesSection.id"
    [plot]="servicesSection.plot"
    [title]="servicesSection.title"
    [description]="servicesSection.description"
    [titleButtonId]="showAllServicesButton ? 'show-all-services-button' : undefined"
    [titleButton]="showAllServicesButton ? t('servicesSection.seeAllServicesButton') : undefined"
    (titleButtonClick)="navigateToAllServices()"
    titleAlignment="left"
  >
    <div class="grid gap-x-md gap-y-lg md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      @for (item of servicesSection.items; track item) {
        <cft-call-to-action
          [attr.id]="item.id + '-service-card'"
          [callToAction]="item.callToAction"
          callToActionStyle="content"
        >
          <cft-horizontal-cta-card
            class="cursor-pointer"
            [title]="item.title"
            [description]="item.shortDescription"
            [icon]="item.icon"
            [chip]="item.chip"
            [elevation]="itemsElevation"
            [separator]="itemsSeparator"
          >
            <ng-container content="action">
              @switch (itemsCallToActionAppearance) {
                @case ('link') {
                  <cft-call-to-action [callToAction]="item.callToAction" callToActionStyle="link"> </cft-call-to-action>
                }
                @case ('button') {
                  <cft-call-to-action
                    [callToAction]="item.callToAction"
                    callToActionStyle="button-sm"
                    [buttonStyle]="callToActionMapping[itemsCallToActionColor]"
                  >
                  </cft-call-to-action>
                }
              }
            </ng-container>
          </cft-horizontal-cta-card>
        </cft-call-to-action>
      }
    </div>
  </cft-content-section>
</ng-container>
